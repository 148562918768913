<template>
    <div>
      <MainBannerSec :language = "dominioactual" />
      <SolucionesSec :language = "dominioactual"/>
  </div>
</template>

<script>
import MainBannerSec from './MainBannerSec.vue'
import SolucionesSec from './SolucionesSec.vue'

export default {
  components: {
    MainBannerSec, SolucionesSec
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.track()
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/cybersecurity' })
    }
  }
}
</script>

<style>

</style>
