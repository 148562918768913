<template>
<div>
      <section id="soluciones" class="values" v-if="!(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Mantené la protección de tu negocio</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'Vicarius', params: { languager:'es' }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/sec/vicarius.webp" class="img-fluid" alt="">
                <h3>Application Vulnerability Management</h3>
                <p style="color:#2c3e50;">Gestioná y Mitigá de forma eficiente las vulnerabilidades de las aplicaciones de la mano de Topia</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'CyberObserver', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/sec/cyberobserver.webp" class="img-fluid" alt="">
                <h3>Compliance Monitoring</h3>
                <p style="color:#2c3e50;">Una solución de monitoreo de controles continuos (CCM) y gestión de la postura de seguridad en la nube (CSPM). Permite a los líderes de gestión de riesgos y seguridad monitorear continuamente sus herramientas empresariales y alinear su postura de ciberseguridad con los principales marcos, regulaciones y estándares en las instalaciones y en la nube.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Splunk', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/splunk.webp" class="img-fluid" alt="">
                <h3>Event Monitoring</h3>
                <p style="color:#2c3e50;"> Supervise, detecte, investigue y responda a las amenazas con análisis de seguridad basados en la nube y en tiempo real.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>Encryption and TimeStamping</h3>
                <p style="color:#2c3e50;"> </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/secadvi.webp" class="img-fluid" alt="">
                <h3>Security Advisor</h3>
                <p style="color:#2c3e50;">Servicios profesionales Integrales, una mirada ampliada para la seguridad tu negocio.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/phishing.webp" class="img-fluid" alt="">
                <h3>Security Awareness</h3>
                <p style="color:#2c3e50;">¿Están tus usuarios listos para atender a las ciberamenazas de todos los días? Diseña con nosotros un plan que se adapte a tus procesos de negocio y permita proteger a tus usuarios de forma integral</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section id="soluciones" class="values" v-if="(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Tailor-made solutions</h2>
            <p>Keep your business protected</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'VicariusEn', params: { userId: 123 }}">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/sec/vicarius.webp" class="img-fluid" alt="">
                <h3>Application Vulnerability Management</h3>
                <p style="color:#2c3e50;"></p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'CyberObserverEn', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/sec/cyberobserver.webp" class="img-fluid" alt="">
                <h3>Compliance Monitoring</h3>
                <p style="color:#2c3e50;"> </p>
              </div>
              </router-link>
            </div>
                        <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'SplunkEn', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/splunk.webp" class="img-fluid" alt="">
                <h3>Event Monitoring - SIEM</h3>
                <p style="color:#2c3e50;"></p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>Endpoint Protection</h3>
                <p style="color:#2c3e50;"> </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'CybersecurityEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/secadvi.webp" class="img-fluid" alt="">
                <h3>Security Advisor</h3>
                <p style="color:#2c3e50;"> </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'CybersecurityEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/sec/phishing.webp" class="img-fluid" alt="">
                <h3>Security Awareness</h3>
                <p style="color:#2c3e50;"> </p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
</div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.col-lg-5 {
    flex: 0 0 50%;
    max-width: 50%;
}
.img-fluid {
    max-width: 100%;
    height: 236px;
    width: auto;
}
.values .box img {
    padding: 30px 30px;
}
</style>
